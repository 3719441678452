import React from 'react'
import './footer.css'
import logo from '../../assets/logo.png'


const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <div className='kk__footer section__padding'>
      <div className='kk__footer-links'>
        <div className='kk__footer-links_logo'>
          <img src={logo} alt='logo' />
        </div>

        <div className='kk__footer-links_div'>
          <h4>Linkit</h4>
          <p><a href='#meista'>Meistä</a></p>
          <p><a href='#palvelut'>Palvelut</a></p>
          <p><a href='#yhteystiedot'>Yhteystiedot</a></p>
        </div>
        <div className='kk__footer-links_div'>
          <h4>Yhteystiedot</h4>
          <p>Koodikonna</p>
          <p>Y-tunnus: 3245976-4</p>
          <p><a href='tel:+358405804170'>040 580 4170</a></p>
          <p><a href='mailto:asiakaspalvelu@koodikonna.fi'>asiakaspalvelu@koodikonna.fi</a></p>
        </div>
      </div>

      <div className='kk__footer-copyright'>
        <p>© {year} KoodiKonna. Kaikki oikeudet pidätetään.</p>
      </div>

    </div>
  )
}

export default Footer