import React from 'react'
import Feature from '../../components/feature/Feature'
import './whatkk.css'

const Whatkk = () => {
  return (
    <div className='kk__whatkk section__margin' id='meista'>
      <div className='kk__whatkk-feature'>
        <Feature title="Mikä KoodiKonna?" text="Teknistä tukea tarjoava monitaituri."/>
      </div>
      <div className='kk__whatkk-heading'>
        <h1 className='gradient__text'>Tekninen ympäristö on mukavuusa&shy;luettani!</h1>

        {/* <p>Explore us</p> */}
      </div>
      <div className='kk__whatkk-container'>
      <Feature title="Tekninen osaaminen" text="Teknisten töiden kanssa ongelmanratkaisukyky on tärkeässä osassa prosessia. Kaikki ei suju aina niin kuin pitää, joten silloi pitää olla työkalupakissa kikkoja niiden ratkaisuun."  />
      <Feature title="Kommunikaatio" text="Asioista keskustelemalla molempi osapuoli on perillä siinä, että missä mennään ja mihin ollaan menossa. Tällöin kummallekkaan osapuolelle ei jää mikään epäselväksi ." />   
      <Feature title="Luottamus" text="Luottamuksen kasvattaminen on pitkä prosessi.  Mulle tärkeetä on, että jos hommista sovitaan, niin sillon ne myös hoidetaan."  />   
      </div>
    </div>
  )
}

export default Whatkk