import React from 'react'
import {Helmet} from "react-helmet"
import {Footer, Blog, Contact, Features, Whatkk, Header} from './containers';
import { Brand, CTA, Navbar} from './components';
import './app.css'


const App = () => {
  return (
    <div className='App'> 
    <Helmet>
      <meta charSet='utf-8' />
      <title>KoodiKonna</title>
      <meta name="description" content="Teknistä tukea tajoava monitaituri."/>
    </Helmet>
       <div className='gradient__bg'>
        <Navbar />
        <Header />
       </div>
       <Brand />
       <Whatkk />
       <Features />
       <Contact />
       <Footer />
    </div>
  )
}

export default App