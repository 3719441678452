import React, { useState } from 'react';
import Feature from '../../components/feature/Feature';
import './contact.css'

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Process the form data here (e.g. send to an API or save to a database)
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className='kk__contact section__margin'>

      <Feature title='Ota yhteyttä' text='Voit kysyä mitä vain!' />

      <div id='yhteystiedot' className='kk__contact-contacts'>
        <p><b>Puh: </b><a href='tel:+358405804170'>040 580 4170</a></p>
        <p><b>S-posti: </b><a href='mailto:asiakaspalvelu@koodikonna.fi'>asiakaspalvelu@koodikonna.fi</a></p>
      </div>

      {/* <div className='kk__contact__form'>

        <form onSubmit={handleSubmit}>
          <div className='kk__contact__form-container'>
            <input
              type="text"
              id="name"
              name="name"
              placeholder='Nimi'
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder='S-posti'
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className='kk__contact__form-container-send'>
            <textarea
              id="message"
              name="message"
              placeholder='Viesti'
              value={formData.message}
              onChange={handleChange}
            />
            <button type="submit">Lähetä</button>
          </div>
        </form>
      </div> */}
    </div>
  );
};

export default Contact;
