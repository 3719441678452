import React from 'react'
import Feature from '../../components/feature/Feature'
import './features.css'


const featuresData = [
  {
    title: 'Verkkosivut',
    text: 'Verkkosivut voidaan luoda asiakkaan haluamalla rakentajalla. Esimerkiksi Wordpress, HubSpot tai React'
  },
  {
    title: 'Verkkokauppa',
    text: 'Suosituin alusta verkkokauppalle on Shopify, mutta myös Wordpressillä pystyy luomaan erinomaisen verkkokaupan.'
  },
  {
    title: 'Verkkosivujen päivitys',
    text: 'Verkkosivuja tulee huoltaa, jotta ne pysyvät toiminnassa ilman katkoksia. '
  },
  {
    title: 'Muut korjaustyöt',
    text: 'Korjaustyö voi olla mitä vain verkkosivuihin liittyvää. Ottamalla yhteyttä voidaan selvittää mikä on paras tapa edetä ongelman kanssa.'
  },
]

const Features = () => {
  return (
    <div className='kk__features section__padding' id='palvelut'>
      <div className='kk__features-heading'>
        <h1 className='gradient__text '>Tarjoamamme erilaisia palveluita asiakkaan tarpeisiin</h1>
        <p>Kysy apua ongalmaasi!</p>
      </div>
      <div className='kk__features-container'>
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.key} />
        ))}
      </div>
    </div>
  )
}

export default Features