import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png'
import './navbar.css'

const Menu = () => (
  <>
    <p><a href='#meista'>Meistä</a></p>
    <span className='kk__divider-solid'/>
    <p><a href='#palvelut'>Palvelut</a></p>
    <span className='kk__divider-solid'/>
    <p><a href='#yhteystiedot'>Yhteystiedot</a></p>
    <span className='kk__divider-solid last-child'/>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  return (
    <div className='kk__container-content'>
      <div className='kk__navbar'>
        <div className='kk__navbar_logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='kk__navbar-links'>
          <div className='kk__navbar-links_container'>
            <Menu />
          </div>
        </div>
        <div className='kk__navbar-contact'>
          <p><a href='#yhteystiedot'>Ota yhteyttä</a></p>
        </div>
        <div className='kk__navbar-menu'>
          {toggleMenu
            ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
          }
          {toggleMenu && (
            <div className='kk__navbar-menu_container slide-left'>
              <div className='kk__navbar-container'>
                <Menu />
                <div className='kk__navbar-container-contact'>
                  <p><a href='#yhteystiedot'>Ota yhteyttä</a></p>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  )
}

export default Navbar