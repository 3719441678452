import React from 'react'
import './brand.css'
import {css, javascript, html, react, php} from './imports'

const Brand = () => {
  return (
    <div className='kk__brand section__padding'>
      <div>
      <img src={html} alt='html'/>
      </div>
      <div>
      <img src={css} alt='css'/>
      </div>
      <div>
      <img src={javascript} alt='javascript'/>
      </div>
      <div>
      <img src={react} alt='react'/>
      </div>
      <div>
      <img src={php} alt='php'/>
      </div>
    </div>
  )
}

export default Brand