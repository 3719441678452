import React from 'react'
import './header.css'
import people from '../../assets/people.png'
import ai from '../../assets/ai.png'


const Header = () => {
  return (
    <div className='kk__header section__padding'>
      <div className='kk__header-content'>
        <h1 className='gradient__text'>Rakennetaan yhdessä jotain parempaa</h1>
        <p>Teknisten ongelmien ratkaisuun tarvitaan ammattitaitoista ja asoihin perehtynyttä henkilöä. Oli ne sitten webhotellin DNS-asetuksien muuttamista, verkkokaupan luontia tai CSS-tiedostojen muokkaamista. </p>
        {/* <div className='kk__header-content__input'>
          <input type='email' placeholder='Sähköpostiosoite' />
          <button type='button'>Aloitetaan!</button>
        </div> */}
        {/* <div className='kk__header-content__people'>
          <img src={people} alt='people' />
          <p>Moni yritys uskoo meihin</p>
        </div> */}

       
      </div>
      <div className='kk__header-image'>
          <img src={ai} alt='ai' />
        </div>
    </div>
  )
}

export default Header